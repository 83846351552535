import React from 'react';
import './AboutIntro.css';
import team_1 from '../bobby_new.jpg';
import team_2 from '../RobLo.jpg';
import {Link} from 'react-router-dom';
import dvbelogo from '../DvbeLogo.png';
import sdvoblogo from '../SDVOBLogo.png';


function AboutIntro() {
    return (
        <>
        <div className="introduction-wrapper">
            <div className="introduction-container">
                <div className="introduction-text-container1">
                <h3>About Us</h3>
                <p>Founded in 2006, Avere provides IT talent and project management services to help world-class companies lead.
                   
                </p>
                </div>
            </div>
        </div>
        <div className="aboutus-container">
            <div className="aboutus-text">
            <p>Avere is a consulting firm that partners with leaders in business to tackle their most important challenges and 
                capture their greatest opportunities. Our success is dependent on a spirit of deep collaboration and a community of diverse
                individuals determined to make the world and each other better every day.
                </p>  
            </div>
          
        </div>
        <div className="flagGradient"> </div>
        <div className="enterprise-container">
        <div className="sdvosb-container">
            <Link to='/' className="footer-logo2">
            <img src={sdvoblogo} alt="sdvoblogo"/></Link>
            </div>
            <h1>We are a proud certified service-disabled veteran-owned small business and part of the disabled veteran business enterprise program. </h1>
            <div className="dvbe-container">
            <Link to='/' className="footer-logo1">
            <img src={dvbelogo} alt="dvbelogo"/></Link>
            </div>
        </div>
        <div className="flagGradient"> </div>
        <div className="values-container">
            <div className="values-textbox">
            <h1>Our Values:</h1>
                <ul>
                    <li>Integrity: We are truthful and honest.</li>
                    <li>Excellence: We confidently pursue the highest quality.</li>
                    <li>Together: We respect one another and draw strength from our differences.</li>
                    <li>For the Better: We always work to improve.</li>
                    <li>Courage: We think boldy and act upon it.</li>
                </ul>
            </div>
        </div>
        <div className="team-title">
            Meet our Team
        </div>
        <div className="executive-super-wrap">
        <div className="executive-wrapper">
            <div className="executive-container1">
                <div className="team_1">
                <img src={team_1} alt="Bobby Gunn"/>
                <div className="text-aligner">
                <h2>Bobby Gunn</h2>
                <h3>Principal & Founder</h3>
                <p>A United States Navy Veteran and graduate of California State University Sacramento with a degree in Psychology. 
                    Mr. Gunn has more than 20 years of experience in the IT staffing and consulting services primarily focused on the private and public sector in Northern California. 
                    Prior to starting Avere he worked with two of the largest national IT staffing firms Comsys and Modis in recruiting and account management roles. 
                    While at Comsys he served as the public sector account manager for the state of California and was 
                    responsible for building project teams of up to 20 consultants of diverse technical skill sets.</p>
                    <Link className="social-icon-link linkedin"
                            to="//www.linkedin.com/in/robert-gunn-240b76/"
                            target="_blank"
                            aria-label='Linkedin' >
                                <i className= 'fab fa-linkedin'/>
                            </Link>
                            </div>

                </div>
                </div>
                <div className="executive-container2">
                <div className="team_2">
                <img src={team_2} alt="Rob Lo"/>
                <h2>Rob Lo</h2>
                <h3>Managing Partner</h3>
                <p>Management Consulting executive with over 20 years of experience leading diverse IT transformation, modernization, 
                    and integration projects. Mr. Lo has held long term leadership roles at IBM Global Services, Xerox Government Healthcare, 
                    and KPMG LLP. In the last 15 years, Mr. Lo's focus has been on assisting State and Local Government agencies with 
                    various efforts to adapt to regulatory demands, evolving technologies landscape, and meeting departmental, State, and Federal fiscal commitments. 
                    Mr. Lo provides his clients a blend of technical acumen and executive insight to help execute diverse strategic initiatives.</p>
                    <Link className="social-icon-link linkedin"
                            to="//www.linkedin.com/in/rob-lo-768b59/"
                            target="_blank"
                            aria-label='Linkedin' >
                                <i className= 'fab fa-linkedin'/>
                            </Link>

                </div>
            </div>
        </div>
        </div>
        </>

    );
  }
  
export default AboutIntro;