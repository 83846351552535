//Home Page / Clients

import React, {Component} from 'react';
import '../App.css';
import './Clients.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import clients_logo1 from '../CaDMV.png';
import clients_logo2 from '../DepartmentofCorrectsRehabLogo.jpg';
import clients_logo3 from '../OfficeOfAttorneyGeneralLogo.png';
import clients_logo4 from '../CoveredCaliforniaCropped.png';
import clients_logo5 from '../DepartmentofTechnologyLogo.png';
import clients_logo6 from '../DepartmentofWaterResourcesLogo.png';
import {Link} from 'react-router-dom';


class Clients extends Component {
  render(){
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      className: "clients_slides"
    }
    return(
      <div className= 'clients-container'>
        <h2> Clients </h2>
        <Slider {...settings}>
          <div>
            <Link 
              to="//www.dmv.ca.gov/portal/"
              className='clients_logo1'
              target='_blank'
              rel='noreferrer' 
            >
              <img src={clients_logo1} alt="Ca DMV"/>
            </Link>
          </div>
          <div>
            <Link 
              to="//www.cdcr.ca.gov/"
              className='clients_logo2'
              target='_blank'
              rel='noreferrer'  
            >
              <img src={clients_logo2} alt="CA Dept. of Corrections and Rehab"/>
            </Link>
          </div>
          <div>
            <Link 
              to="//oag.ca.gov/"
              className='clients_logo3'
              target='_blank'
              rel='noreferrer'  
            >
              <img src={clients_logo3} alt="Office of the Attorney General"/>
            </Link>
          </div>
          <div>
            <Link 
              to="//www.coveredca.com/"
              className='clients_logo4'
              target='_blank'
              rel='noreferrer' 
            >
              <img src={clients_logo4} alt="Covered California"/>
            </Link>
          </div>
          <div>
            <Link 
              to="//cdt.ca.gov/"
              className='clients_logo5'
              target='_blank'
              rel='noreferrer'  
            >
              <img src={clients_logo5} alt="CA Dept. of Technology"/>
            </Link>
          </div>
          <div>
            <Link 
              to="//water.ca.gov/"
              className='clients_logo6'
              target='_blank'
              rel='noreferrer'  
            >
              <img src={clients_logo6} alt="CA Dept. of Water Resources"/>
            </Link>
          </div>
        </Slider>
      </div>
    );
  }
}
  
  export default Clients;