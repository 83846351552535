import React from 'react'
import './SDLCsupport.css';

function SDLCsupport() {
    return (
        <>
            <div className='sdlc-header'>
           </div>
           <div className='sdlc-wrapper'>
           <div className='sdlc-container'>
                <h4>SDLC Support Services</h4>
                <p>In the face of continuous changes in the business environment as well as regulatory requirements, 
                    companies are forced to constantly adapt their business processes to new regulatory requirements 
                    as well as to optimize them to improve business efficiency. By taking advantage of Avere's knowledge 
                    and experience we streamline the process in which your company adapts to these constant changes.</p>
           </div>
           <div className='sdlc-container2'>
                <h5>Producing the highest quality software at the lowest cost in the shortest time possible</h5>
                <p>In order to meet these challenges, Avere offers the following professional services related to 
                    development and implementation of dedicated IT systems covering full software development cycle:</p>
                    <ul>
                        <li>Business requirements: definition, specification, identification and analysis of regulatory requirements</li>
                        <li>Software testing</li>
                        <li>Gap analysis in source systems</li>
                        <li>Defining and implementing ETL processes Design of IT systems Software development</li>
                        <li>Comprehensive management of the entire implementation project.</li>
                        <li>Post-implementation service and support</li>
                    </ul>
           </div>
           </div>
        </>
    );
  }


export default SDLCsupport;