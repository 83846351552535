import React from 'react';
import '../../App.css';
import Careerspage from '../Careerspage';

function Careers() {
    return (
      <>
        <Careerspage/>
      </>
    );
  }

  export default Careers