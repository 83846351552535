import './App.css';
import Navbar from './components/Navbar';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './components/pages/Home';
import Aboutus from './components/pages/AboutUs';
import Services from './components/pages/Services';
import Projects from './components/pages/Projects';
import News from './components/pages/News';
import Careers from './components/pages/Careers';
import Contactus from './components/pages/ContactUs';
import Footer from './components/Footer';
import TermsConditions from './components/pages/TermsConditions';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import CyberSecurity from './components/pages/CyberSecurity';
import ProjectManagement from './components/pages/ProjectManagement';
import Cloud from './components/pages/Cloud';
import ITadvisory from './components/pages/ITadvisory';
import ITstaffing from './components/pages/ITstaffing';
import sdlc from './components/pages/Sdlc';
import ScrollToTop from './components/ScrollToTop';
import DMVProposal from './components/Proposal';
import Conference from './components/Conference';

function App() {
  return (
    <>
    <Router>
     <Navbar />
     <ScrollToTop />
     <Switch>
     <Route path='/' exact component={Home} />
     <Route path='/aboutus' exact component={Aboutus} />
     <Route path='/services' exact component={Services} />
     <Route path='/projects' exact component={Projects} />
     <Route path='/news' exact component={News} />
     <Route path='/careers' exact component={Careers} />
     <Route path='/contactus' exact component={Contactus} />
     <Route path='/termsconditions' exact component={TermsConditions}/>
     <Route path='/privacypolicy' exact component={PrivacyPolicy}/>
     <Route path='/cybersecurity' exact component={CyberSecurity}/>
     <Route path='/projectmanagement' exact component={ProjectManagement}/>
     <Route path='/cloud' exact component={Cloud}/>
     <Route path='/itadvisory' exact component={ITadvisory}/>
     <Route path='/itstaffing' exact component={ITstaffing}/>
     <Route path='/sdlc' exact component={sdlc}/>
     <Route path='/vendor-day' exact component={DMVProposal} />
     <Route path='/conference' exact component={Conference} />
     </Switch>
    <Footer /> 
     </Router>
     </>
  );
}

export default App;
