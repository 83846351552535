import React from 'react'
import './CyberSecurityComp.css';

function CyberSecurityComp() {
    return (
        <>
            <div className='cybersecurity-header'>
           </div>
           <div className='cybersecurity-wrapper'>
           <div className='cybersecurity-container'>
                <h4>Cyber Security</h4>
                <p>A well-protected business has the potential to be confident and the most innovative. 
                    The smartest businesses don't just manage cyber risk, they use it as a source of 
                    growth and market edge. Technology makes many things possible, but possible doesn't 
                    always mean safe. As cyber threats grow in volume and sophistication and technology 
                    becomes essential for meeting the needs of your customers, employees, suppliers and 
                    society, your cyber security must build resilience and trust.</p>
           </div>
           <div className='cybersecurity-container2'>
                <h5>How can Avere help you?</h5>
                <p>Avere helps you create a resilient and trusted digital world, even in the face of 
                    evolving threats. That's because we bring a combination of technological expertise, 
                    deep business knowledge, and creative professionals who are passionate about protecting 
                    and building your business. Together, let's create a trusted digital world, so you can 
                    push the limits of what's possible.</p>
           </div>
           <div className='cybersecurity-container3'>
                <h5>Services:</h5>
                <ul>
                    <li>Digital Forensics Services</li>
                    <li>Vulnerability and Risk Assessments</li>
                    <li>Information Security Strategy and Design</li>
                    <li>Policy and Plan Development</li>
                    <li>Configuration Management, Design, and Remediation</li>
                    <li>Enterprise security architecture design and re-design</li>
                </ul>
           </div>
           </div>
        </>
    );
  }


export default CyberSecurityComp;