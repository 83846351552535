import React from 'react'
import '../../App.css';
import Newspage from '../Newspage';

function News() {
    return (
      <>
        <Newspage/>
      </>
    );
  }

  export default News