import React from 'react'
import '../../App.css';
import CyberSecurityComp from '../CyberSecurityComp';

function CyberSecurity() {
    return (
      <>
        <CyberSecurityComp/>
      </>
    );
  }

  export default CyberSecurity