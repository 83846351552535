import React from 'react'
import './ITstaff.css';

function ITstaff() {
    return (
        <>
            <div className='ITstaff-header'>
           </div>
           <div className='ITstaff-wrapper'>
           <div className='ITstaff-container'>
                <h4>IT Staffing</h4>
                <p>In order to function efficiently in today's industry, agility is crucial. 
                    At Avere we take our time to listen and understand our clients' needs. 
                    Avere has gained a rare overview into the intersection of talent and business. 
                    One among the leading IT staffing firms, we enable you to achieve and optimize 
                    the most strategic and variable component to business success right people, 
                    with right skills, competencies, and attitudes.</p>
                <p>We are an unconventional technical staffing agency and our partnership model 
                    focuses on your unique needs- supporting short-term, seasonal or temporary 
                    IT staffing agencies requirements, providing contract-to-hire talent for 
                    project-based support with the option to hire the contractors as permanent 
                    employees, or finding the permanent top talent your company needs today. 
                    Our IT staffing services cover Contract Staffing with onsite, offsite, and 
                    offshore (IT staff outsourcing) services.</p>
           </div>
           </div>
        </>
    );
  }


export default ITstaff;