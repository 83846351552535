//Home Page / IntegrationPartners
import React, {Component} from 'react';
import '../App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import partners_logo1 from '../SGS2Logo.png';
import partners_logo2 from '../KPMGLogo.png';
import partners_logo3 from '../OsaasLogo.png';
import partners_logo4 from '../HighlandConsultingGroupLogo.png';
import partners_logo5 from '../GuidehouseLogo.png';
import {Link} from 'react-router-dom';
import './IntegrationPartners.css';
  
class IntegrationPartners extends Component {
  render(){
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      className: "partners_slides"
    }
    return(
      <div className= 'partners-container'>
        <h2> Integration Partners </h2>
        <Slider {...settings}>
          <div>
            <Link 
              to="//www.sgs.com/"
              className='partners_logo1'
              target='_blank'
              rel='noreferrer'  
            >
              <img src={partners_logo1} alt="SGS2"/>
            </Link>
          </div>
          <div>
            <Link 
              to="//www.kpmg.us/"
              className='partners_logo2'
              target='_blank'
              rel='noreferrer'  
            >
              <img src={partners_logo2} alt="KPMG"/>
            </Link>
          </div>
          <div>
            <Link 
              to="//oag.ca.gov/"
              className='partners_logo3'
              target='_blank'
              rel='noreferrer'  
            >
              <img src={partners_logo3} alt="Osaas"/>
            </Link>
          </div>
          <div>
            <Link 
              to="//highlandsconsulting.com/"
              className='partners_logo4'
              target='_blank'
              rel='noreferrer'  
            >
              <img src={partners_logo4} alt="Highland Consulting Group"/>
            </Link>
          </div>
          <div>
            <Link 
              to="//guidehouse.com/"
              className='partners_logo5'
              target='_blank'
              rel='noreferrer'  
            >
              <img src={partners_logo5} alt="Guidehouse"/>
            </Link>
          </div>
        </Slider>
      </div>
    );
  }
}
    

export default IntegrationPartners;