import './Projectspage.css';
import React from 'react';
import {Link} from 'react-router-dom';

function Projectspage() {
    return (
        <>
        <div className='projects-header'>
            <h3>Projects we are working on</h3>
            <p>An overview of the projects Avere is currently engaged with.</p>
        </div>
        <div className='projects-wrapper'>
          <h4>Key Projects</h4>
          <div className='projects-text-container'>
            <p>Avere leaders play key advisory roles in a number of projects with the aim of tackling some of the most significant challenges through public-private collaboration.</p>
            <h3>DMV's Real ID Project - RADVP</h3>
            <p>Working on the implementation of our proposed solution to improve the efficiency of the DMV's Real ID process by streamlining the process.
              Using optical character recognition to reduce the amount of documentation needed to be reviewed by human hands.
              Avere's goal was to meet and exceed the expectations of the DMV and the expectations of Californians. As the private sector
              improves in customer facing UI the public sector 
            </p>
            <p>
              <Link 
                to ="//www.dmv.ca.gov/portal/driver-licenses-identification-cards/real-id/what-is-real-id/"
                target='_blank'
                rel='noferrer'
              >
                Read more
              </Link>
            </p>
          </div>
        </div>
</>
    );
  }

export default Projectspage;