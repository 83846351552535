import React from 'react'
import '../../App.css';
import Privacy from '../Privacy';

function PrivacyPolicy() {
    return (
      <>
        <Privacy/>
      </>
    );
  }

  export default PrivacyPolicy