import React from 'react'
import '../../App.css';
import ITadvisoryservices from '../ITadvisoryservices';

function ITadvisory() {
    return (
      <>
        <ITadvisoryservices/>
      </>
    );
  }

  export default ITadvisory