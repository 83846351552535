//Home Page / California Software Licensing Program


import './SoftwarePartners.css';
import React, {Component} from 'react';
import '../App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ca_software_logo1 from '../FaroSolutionsLogo.png';
import ca_software_logo2 from '../gitlab.png';
import ca_software_logo3 from '../IvaluaLogo.png';
import ca_software_logo4 from '../uipathLogo.png';
import ca_software_logo5 from '../abbyyLogo.png';
import ca_software_logo6 from '../redHatLogo.png';
import ca_software_logo7 from '../newRelicLogo.png';
import ca_software_logo8 from '../DatadogLogo.png';
import {Link} from 'react-router-dom';
    
class SoftwarePartners extends Component {
  render(){
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      className: "ca_software_slides"
    }
    return(
      <div className= 'ca_software-container'>
      <h2>Software Partners </h2>
      <Slider {...settings}>
        <div className="logo1">
          <p>*SLP</p>
          <Link 
            to="//www.farosolutions.com/"
            className='ca_software_logo1'
            target='_blank'
            rel='noreferrer'
          >
            <img src={ca_software_logo1} alt="Faro"/>
          </Link>
        </div>
        <div>
          <Link 
            to="//www.uipath.com/"
            className='ca_software_logo4'
            target='_blank'
            rel='noreferrer'
          >
            <img src={ca_software_logo4} alt="UI Path"/>
          </Link>
        </div>
        <div>
          <Link 
            to="//www.abbyy.com/"
            className='ca_software_logo5'
            target='_blank'
            rel='noreferrer'
          >
            <img src={ca_software_logo5} alt="ABBYY"/>
          </Link>
        </div>
        <div className="logo2">
          {/* <p>*SLP</p> */}
          <Link 
            to="//about.gitlab.com/"
            className='ca_software_logo2'
            target='_blank'
            rel='noreferrer'
          >
            <img src={ca_software_logo2} alt="GitLab"/>
          </Link>
        </div>
        <div className="logo3">
          <p>*SLP</p>
          <Link 
            to="//www.ivalua.com/"
            className='ca_software_logo3'
            target='_blank'
            rel='noreferrer' 
          >
            <img src={ca_software_logo3} alt="ivalua"/>
          </Link>
        </div>
        <div>
          <Link
            to="//www.redhat.com"
            className='ca_software_logo6'
            target='_blank'
            rel='noreferrer'
          >
            <img src={ca_software_logo6} alt="Red Hat"/>
          </Link>
        </div>
          <div>
          <Link
            to="//www.newrelic.com"
            className='ca_software_logo7'
            target='_blank'
            rel='noreferrer'
          >
            <img src={ca_software_logo7} alt="New Relic"/>
          </Link>
          </div>
          <div>
          <Link
            to="//www.datadoghq.com"
            className='ca_software_logo8'
            target='_blank'
            rel='noreferrer'
          >
            <img src={ca_software_logo8} alt="DataDog"/>
          </Link>
          </div>
      </Slider>
      </div>
    );
  }
}

export default SoftwarePartners;