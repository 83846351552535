import './Privacy.css';
import React from 'react';

function Privacy() {
    return (
        <>
        <div className='privacy-container'>
    <h3>Privacy Policy</h3>
    <h2>Last updated: 8/31/2021</h2>
    <h4>Your Private Information</h4>
    <ul>
        <li>We do not collect and utilize the information that you provide.</li>
        <li>We will not use your information to contact you in the future with promotional offers.</li>
        <li>We will not rent or sell your information into third parties outside Avere and its group companies (including any parent, subsidiaries and affiliates) without your consent.</li>
        <li>Contact us by email if you have further questions regarding our privacy policy or your information.</li>
    </ul>
    <h4>Cookie Usage</h4>
    <ul>
    <li>We do not use cookies to collect information from visitors.</li>
    <li>We take the security of your personal information very seriously. Any and every section of this website does not collect personal or financial information from its users.</li>
    <li>If you feel that we are not following this privacy policy, please contact us as soon as possible.</li>
    </ul>
    <h4>Contact Us</h4>
        <p>If you have any questions about these Terms, please contact us.</p>
        </div>
</>
    );
  }

export default Privacy;