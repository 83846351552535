import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'
import logo from '../Avere_Logo_White.png';

function Footer() {
    return (
        <>
        <div className='footer-container'>
            <div className="footer-links">
                <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>Pages</h2>
                        <Link to='/'>Home</Link>
                        <Link to='/aboutus'>About Us</Link>
                        <Link to='/services'>Services</Link>
                        <Link to='/projects'>Projects</Link>
                        <Link to='/news'>News</Link>
                        <Link to='/careers'>Careers</Link>
                        <Link to='/contactus'>Contact Us</Link>
                    </div>
                    </div>
                    <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>Services</h2>
                        <Link to='/cybersecurity'>Cyber Security</Link>
                        <Link to='/projectmanagement'>Project Management</Link>
                        <Link to='/cloud'>Cloud Solutions</Link>
                        <Link to='/itadvisory'>IT Advisory</Link>
                        <Link to='/itstaffing'>IT Staffing</Link>
                        <Link to='/sdlc'>SDLC Support</Link>
                    </div>
                    </div>
                    <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>Legal</h2>
                        <Link to='/PrivacyPolicy'>Privacy Policy</Link>
                        <Link to='/TermsConditions'>Terms and Conditions</Link>
                    </div>
                    </div>
                </div>
                <div className="social-media">
                    <div className="social-media-wrap">
                        <div className="footer-logo">
                            <Link to='/' className="navbar-logo">
                                <img 
                                src={logo} alt="Logo"/>
                            </Link>
                        </div>
                        <div className="website-rights">All rights reserved by ©AvereUSA 2024 </div>
                        <div className="social-icons">
                            <Link className="social-icon-link linkedin"
                            to="//www.linkedin.com/company/avere-technology-staffing/about/"
                            target="_blank"
                            aria-label='Linkedin' >
                                <i className= 'fab fa-linkedin'/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            </>
    )
}

export default Footer
