import React from 'react'
import '../../App.css';
import SDLCsupport from '../SDLCsupport';

function Sdlc() {
    return (
      <>
        <SDLCsupport/>
      </>
    );
  }

  export default Sdlc