import React from 'react'
import './CloudSolution.css';
import aws_marketplace_logo from '../AWSMarketplaceLogo.png';
import { Link } from 'react-router-dom';

function CloudSolution() {
    return (
        <>
        <div className='cloud-header'>
        </div>
        <div className='cloud-wrapper'>
            <div className='cloud-container'>
                <h4>Cloud Solution Services</h4>
                <p>We enable enterprise transformation through innovative applications of the cloud.
                    Combining business acumen, integrated business and technology services, and
                    a people-first approach, we help businesses discover and activate their
                    possible potential.</p>
                <p>Our complete scope of efficient and professional capabilities can lift your business to the cloud and to new heights.</p>
           </div>
           <div className='cloud-container2'>
                <h5>Cloud Migration Services</h5>
                <p>Review existing applications and development practices as well as performing technical analysis to determine steps needed to modernize applications. 
                    Avere offers solutions to modernize your legacy system, to take full advantage of what the cloud has to offer. Improve your application's 
                    modernization process by pairing our experts with your application development team. Together we will assess, plan, refactor, configure, 
                    test, deploy, and transition your existing application portfolio to a cloud platform/container.</p>
           </div>
           <div className='cloud-container2'>
                <h5>Strategy Services</h5>
                <p>We help you realize the benefits of the cloud by aligning technology strategy to business strategy. 
                    We create a strategy that solves your business's particular needs, whether you’re at the start of your 
                    migration or need to re-balance your cloud program. In order to be successful in the cloud, your journey 
                    must navigate pitfalls and opportunities that are unique to your business.</p>
           </div>
           <div className='cloud-container2'>
                <h5>Infrastructure Services</h5>
                <p>Leverage our infrastructure services to assess the health of your stack and check compatability with monitoring solutions. 
                    Deploy these solutions to your environment, will allow us to constantly monitor your infrastructure for any anomalies. </p>
           </div>
           <div className='awsWrapper'>
           <div className='aws-container'>
               <Link 
                to='//aws.amazon.com/marketplace/pp/prodview-bbxlsbvnihc2q'
                target='_blank'
                rel='noreferrer'
               >
                   <img src={aws_marketplace_logo} alt="AWS Marketplace" />
               </Link>
            </div>
            <div className='aws-text'>
                <p>
                    As an AWS Marketplace Skilled Consulting Partner, Avere can work with you to provide database and engineering services on AWS
                    in addition to the cloud services listed above. To inquire more about the services we have to offer, please visit our AWS Marketplace page.
                </p>
            </div>
            </div> 
        </div>
        </>
    );
  }


export default CloudSolution;