   
import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
import Cards from '../Cards';
import IntegrationPartners from '../IntegrationPartners';
import Clients from '../Clients';
import SoftwarePartners from '../SoftwarePartners';
import ContactUsform from '../ContactUsform';

function Home() {
  return (
    <>
      <HeroSection /> 
      <Cards />
      <Clients />
      <IntegrationPartners />
      <SoftwarePartners />
      <ContactUsform />
    </>
  );
}

export default Home;