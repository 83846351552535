import React from 'react'
import '../../App.css';
import Terms from '../Terms';

function TermsConditions() {
    return (
      <>
        <Terms/>
      </>
    );
  }

  export default TermsConditions