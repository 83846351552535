import './Terms.css';
import React from 'react';

function Terms() {
    return (
        <>
        <div className="terms-container">
    <h3>Terms & Conditions</h3>
    <h2>Last updated: 8/31/2021</h2>
    <p>Please read these Terms and Conditions carefully before using the www.avereusa.com website.</p>
    <h4>Content</h4>
    <p>Our website allows you to view information regarding Avere</p>
    <h4>Changes</h4>
    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. 
        If a revision is material we will try to provide at least 30 (change this) days' notice prior
         to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
    <h4>Contact Us</h4>
        <p>If you have any questions about these Terms, please contact us.</p>
        </div>
</>
    );
  }

export default Terms;