import React from 'react'
import '../../App.css';
import Service from '../Service';

function Services() {
    return (
      <>
        <Service/>
      </>
    );
  }

  export default Services