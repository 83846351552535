import React from 'react'
import './ITadvisoryservices.css';

function ITadvisoryservices() {
    return (
        <>
            <div className='ITadvisoryservices-header'>
           </div>
           <div className='ITadvisoryservices-wrapper'>
           <div className='ITadvisoryservices-container'>
                <h4>IT Advisory Services</h4>
                <p>Amid an evolving regulatory environment and increased oversight pressures, 
                    organizations face ongoing challenges to manage and comply with 
                    ever-mounting regulations that are coming their way. At the same time, 
                    organizations are focused on managing new and persistent risks to their 
                    business, while balancing revenue growth and expense saving business priorities.</p>
                <p>In the face of these challenges, managing IT risk and compliance has 
                    become even more critical, as IT failures can lead to reputational damage, 
                    customer and market valuation loss, and an increase in privacy issues and 
                    high-profile legal exposure. In this environment, enhancing IT controls is 
                    crucial to help ensure businesses are managed and controlled appropriately, 
                    and functioning reliably.</p>
           </div>
           </div>
        </>
    );
  }


export default ITadvisoryservices;