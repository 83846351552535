import React from 'react'
import '../../App.css';
import CloudSolution from '../CloudSolution';

function Cloud() {
    return (
      <>
        <CloudSolution/>
      </>
    );
  }

  export default Cloud