import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactUsform.css';
import Popup from './Popup';


export const ContactUsform = () => {

  const [, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  //Form and Alert States
  const [isOpen, setIsOpen] = useState(false);
  const [subject ,setSubject] = useState('');
  const [name ,setName] = useState('');
  const [email ,setEmail] = useState('');
  const [message ,setMessage] = useState('');

  //Form Validation States
  const [subjectError, setSubjectError] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');

  const form = useRef();

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    if(validateForm()){
      return;
    }
      emailjs.sendForm('service_yfxncus', 'template_wwl872g', form.current, 'user_5xKHff5Dv2ognZpMSDpw3')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    clearForm();
  };


  const validateForm = () =>{
    if( checkSubject() || checkName() || checkEmail() || checkMessage()){
      if(checkSubject()){
        setSubjectError('Please enter a subject');
      }
      if(checkName()){
        setNameError('Please enter your name');
      }
      if(checkEmail()){
        setEmailError('Please enter a valid email');
      }
      if(checkMessage()){
        setMessageError('Please provide a message');
      }
      return true;
    }
      return false;
  }

  const checkSubject = () =>{
    if(subject === '' || subject === undefined){
      return true;
    } else{
      return false;
    }
  }

  const checkName = () =>{
    if(name === '' || name === undefined){

      return true;
    } else{
      return false;
    }
  }

  const checkEmail = () =>{
    if(email === '' || email === undefined){
      return true;
    } else{
      return false;
    }
  }

  const checkMessage = () =>{
    if(message === '' || message === undefined){
      return true;
    } else{
      return false;
    }
  }

  const clearForm = () =>{
    setSubject('');
    setName('');
    setEmail('');
    setMessage('');
    setSubjectError('');
    setNameError('');
    setEmailError('');
    setMessageError('');
  }

  return (
    <>
    <div className ="contactus-wrapper">
    <div className = "contactus-container">
    <div className ="contactus-title">
    Contact Us!
    </div>
      <div className ="contactus-form">
    <form ref={form} onSubmit={sendEmail}>
    <label>Subject *</label>
      <input 
      type="text" 
      name="subject" 
      placeholder="Subject"
      value={subject}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={e => setSubject(e.target.value)}
      />
      {(checkSubject()) && 
      (<div 
      style={{color:'red', fontSize:13}}
      >
       {subjectError}</div>)

      }
      <label>Name *</label>
      <input 
      type="text" 
      name="name" 
      placeholder="Name"
      value={name}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={e => setName(e.target.value)}
      />
            {(checkName()) && 
      (<div 
      style={{color:'red', fontSize:13}}
      >
       {nameError}</div>)

      }
      <label>Email *</label>
      <input 
      type="email" 
      name="email" 
      placeholder="Email"
      value={email}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={e => setEmail(e.target.value)}
      />
            {(checkEmail()) && 
      (<div 
      style={{color:'red', fontSize:13}}
      >
       {emailError}</div>)

      }
      <label>Message *</label>
      <textarea 
      name="message" 
      placeholder="Message"
      value={message}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={e => setMessage(e.target.value)}
      />
            {(checkMessage()) && 
      (<div 
      style={{color:'red', fontSize:13}}
      >
       {messageError}</div>)

      }
      <input 
      type="submit" 
      value="Send Message" 
      onClick={validateForm}
      />


    {isOpen && <Popup
      content={<>
        <b>Email Sent!</b>
        <p>Thank you for reaching out to us, we will respond shortly.</p>
      </>}
      handleClose={togglePopup}
    />}
    </form>
    </div>
    </div>
    </div>
    </>
  );
};

export default ContactUsform;