import React from 'react'
import '../../App.css';
import Projectspage from '../Projectspage';

function Projects() {
    return (
      <>
        <Projectspage/>
      </>
    );
  }

  export default Projects