import React from 'react';
import '../../App.css';
import AboutIntro from '../AboutIntro';

function AboutUs() {
  return (
    <>
      <AboutIntro/>
    </>
  );
}

export default AboutUs