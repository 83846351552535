import './Newspage.css';
import React from 'react';
import {Link} from 'react-router-dom';
import sbj from '../sbj.png';

function Newspage() {
    return (
        <>
        <div className='news-header'>
          <h3>News and Updates</h3>
            <p>Keep up with what is happening at Avere!</p>
        </div>
        <div className='news-wrapper'>
        <div className='news-text-container' style={{marginRight:20}}>
            <h3>2022 Sacramento Business Journal Recognition</h3>
            <p>We are honored to have been recognized by Sacramento Business Journal's list of Fastest Growing Companies for 2021.
              Avere ranked #3 on the list in 2021 with 840.17% revenue growth from 2019 to 2021.
              <br/>
              <br/>
              <Link 
                to ="//www.bizjournals.com/sacramento/subscriber-only/2022/08/12/fastest-growing-companies.html"
                target='_blank'
                rel='noreferrer'
              >
                Read more
              </Link>
            </p>
              <div className='news-image-container'>
          <img src={sbj} alt="SBJ Logo"/>
          </div>
            
          </div>
          <div className='news-text-container'>
            <h3>2021 Sacramento Business Journal Recognition</h3>
            <p>We are honored to have been recognized by Sacramento Business Journal's list of Fastest Growing Companies for 2021.
              Avere ranked #9 on the list in 2021 with 185.52% revenue growth from 2018 to 2020.
              <br/>
              <br/>
              <Link 
                to ="//www.bizjournals.com/sacramento/subscriber-only/2021/08/13/fastest-growing-companies.html?b=1628784733%5E21952664"
                target='_blank'
                rel='noreferrer'
              >
                Read more
              </Link>
            </p>
              <div className='news-image-container'>
          <img src={sbj} alt="SBJ Logo"/>
          </div>
          </div>
        </div>
</>
    );
  }

export default Newspage;