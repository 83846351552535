import React from 'react'
import './ProjectManagementpage.css';

function ProjectManagementpage() {
    return (
        <>
            <div className='projectmanagement-header'>
           </div>
           <div className='projectmanagement-wrapper'>
           <div className='projectmanagement-container'>
                <h4>Project Management</h4>
                <p>Enterprise-wide technology projects have become common and preferred by organizations. 
                    Although these projects and programs can bring substantial benefits, if not managed 
                    properly they can be extremely detrimental to business performance. By taking advantage 
                    of the years of experience and high quality skills of Avere's project management team, 
                    we can effectively help you manage the organization's project portfolio, optimize your 
                    project and program performance and increase the expectation of their successful completion.</p>
                <p>Avere's project management services provide an objective, expert approach to managing 
                    the many risks associated with the project and programme implementation. Avere uses well
                     established methodologies and leading concepts and practices, which may be applied a 
                     variety of project types which involve an organization transformation, regulatory compliances,
                      a complex IT systems initiative, or any critical issues.</p>
           </div>
           <div className='projectmanagement-container2'>
                <h5>Portfolio Management Advisory</h5>
                <p>Organizations engages in progressively changing environments which often makes it a struggle 
                    to satisfy shifting business requirements. Without being systematically managed, this can 
                    result in splintered change initiatives that do not benefit the organization due to lack 
                    of efficiency from uncoordinated efforts. Effective portfolio management helps large organizations 
                    make sound decisions by prioritizing the deployment of scarce resources to change initiatives 
                    and maximizing their value to help achieve the organization's strategy. Avere's approach considers 
                    portfolio management at the Board level, focusing on prioritizing and balancing the organization's 
                    resources with its appetite for risk, to help ensure alignment with strategic objectives and enhance 
                    the value of its investment in change initiatives.</p>
           </div>
           </div>
        </>
    );
  }


export default ProjectManagementpage;