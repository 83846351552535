import './Careerspage.css';
import React from 'react';
import { Link } from 'react-router-dom';

function Careerspage() {
    return (
        <>
                <div className="careers-wrapper">
        <div className='careers-container'>
          <div className='careers-container11'>
            <div className='careers-title'>
                Careers
                </div>
                <div className='careers-text'>
                <p>Our team works hard and works together - in full support of one another - 
                  as we each strive to fulfill the company's mission to create client success. 
                  Avere attracts seasoned experts and respected professionals, as well as young 
                  and energetic innovators, who want to deliver value and grow fast.</p>
                  <Link 
                    to="//avere.catsone.com/careers/20290-General" 
                    className="buttonPosition"
                    target='_blank'
                    rel='noreferrer'
                  >
                    <button className='button_positions'>View Open Positions</button> 
                  </Link>
            </div>
        </div>
        </div>
            <div className='careers-image'>
                </div>
            </div>
            <div className='careers-quote'>
              <h3> Vision, Solutions, Execution</h3>
            </div>
            <div className="careers-wrapper">
        <div className='careers-container2'>
        <div className='careers-container22'>
            <div className='careers-team'>
                Join our team!
                </div>
                <div className='careers-team-text'>
                <p>At Avere, the single most important element that contributes to our overall success 
                  is the quality of our team. We look for results-oriented people with top-notch technical 
                  and managerial skills. Positive, energetic, and self-motivated people who embrace a 
                  challenge best reflect our corporate culture. If this sounds like you, please consider 
                  the following open positions.</p>
            </div>
        </div>
        </div>
            <div className='careers-positions'>
            <div id="positions"></div>
              Open Positions
              <ul>
                <li>No current positions</li>
                </ul>
                </div>
            </div>
</>
    );
  }

export default Careerspage;