import './Service.css';
import React from 'react';
import { Link } from 'react-router-dom'


function Service() {
    return (
        <>
        <div className='service-header'>
           <h2>Services and Solutions</h2>
           <h5>Let's work together, and reach new heights.</h5>
        </div>
        <div className="superservice-wrapper">
            <div className='services-wrapper'>
                <div className='services-container1'>
                    <div className='service-1'>
                        <h3>Cyber Security</h3>
                        <p>A data secure business is important in the 21st century, vulnerable businesses are targeted by malicious users. 
                        The volume of cyber threats continues to grow and increase in sophistication. Avere provides security for your 
                        business by ensuring it has a resilient cyber security system capable of thwarting malicious attacks. 
                        Avere employs comprehensive threat detection and response, cyber recovery, and data protection.</p>
                        <div className='service-1-1'>
                            <Link to='/cybersecurity'>Read More</Link>
                        </div>
                    </div>
                    <div className='service-2'>
                        <h3>Project Management</h3>
                        <p>Avere offers a professional approach to managing the risks accompanied with projects. Bringing our specialized skills, 
                        knowledge, and experience to assist companies in making the best possible business decisions. Using industry established 
                        methods, concepts, and practices. Our project management leaders have years of experience successfully managing projects varying greatly in scope and magnitude.</p>
                        <div className='service-1-1'>
                            <Link to='/projectmanagement'>Read More</Link>
                        </div>
                    </div>
                    <div className='service-3'>
                        <h3>Cloud Solutions</h3>
                        <p>The Cloud is a platform for agility and resiliency, it allows large and small companies equal access to computing power. 
                        Avere helps your business by developing and executing your cloud solution, this allows a business to reduce costs while 
                        streamlining and adding innovation. Avere uses innovative applications of the cloud allowing enterprise transformation 
                        allowing your business to navigate swiftly to the cloud and set new boundaries.</p>
                        <div className='service-1-1'>
                            <Link to='/cloud'>Read More</Link>
                        </div>
                    </div>
                </div>
                <div className='services-container2'>
                    <div className='service-4'>
                        <h3>IT Advisory</h3>
                        <p>Avere can help your business develop an appropriate IT strategy, assist with regulatory and compliance needs, 
                        IT risk management and controls, sourcing, cost optimization, logistics and supply chain issues.</p>
                        <div className='service-1-1'>
                            <Link to='/itadvisory'>Read More</Link>
                        </div>
                    </div>
                    <div className='service-5'>
                        <h3>IT Staffing</h3>
                        <p>At Avere we focus on the business impact of technology rather solely on the systems implementation. We are 
                        not tied to any hardware or software suppliers and it results in our advice being independent and geared to the specific 
                        needs of each client. Keeping pace with technology and taking advantage of the tremendous opportunities offered by technological innovation is paramount to us.</p>
                        <div className='service-1-1'>
                            <Link to='/itstaffing'>Read More</Link>
                        </div>
                    </div>
                    <div className='service-6'>
                        <h3>Software Development Life Cycle Support</h3>
                        <p>Avere focuses on applying the SDLC methodology to clients projects. Definition and specification of business 
                        requirements, including identification and analysis of regulatory requirements Gap analysis in source systems Defining and 
                        implementing ETL processes Design of IT systems Software development Software testing Post-implementation service and support 
                        Comprehensive management of the entire implementation project.</p>
                        <div className='service-1-1'>
                            <Link to='/sdlc'>Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
</>
    );
  }

export default Service;