import { React, useState } from 'react'
import emailjs from 'emailjs-com';
import './Conference.css';
import rpaVideo from '../RPA_video.mp4'
import rpaDemoVideo from '../RPA_Demo_Video.mp4'
import { Card, CardContent, Container, Grid2, Typography, Box } from '@mui/material';

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);
  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          setStateMessage('Message sent!');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          setStateMessage('Something went wrong, please try again later');
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );

    // Clears the form after sending the email
    e.target.reset();
  }; return (
    <form onSubmit={sendEmail} className="contact-form">
      <label>Name*</label>
      <input type="text" name="name" required />
      <label>Email*</label>
      <input type="email" name="email" required />
      <label>Phone Number</label>
      <input type="text" name="phoneNumber" />
      <label>Company Name</label>
      <input type="text" name="companyName" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" disabled={isSubmitting} />
      {stateMessage && <p>{stateMessage}</p>}
    </form>
  );
};

function Conference() {
  return (
    <>
      <div className='conference-wrapper'>
        <div className='conference-container'>
          <h4 sx={{fontSize: { xs: '32px', sm: '45px' }}}>eCourts 2024</h4>
        </div>
        <Container>
          <video className="demo" controls>
            <source src={rpaVideo} type="video/mp4" />
          </video>
        </Container>
        <Container sx={{ marginTop: "20px" }}>
          <Box sx={{
            borderRadius: 1,
            bgcolor: '#001d3d',
            marginBottom: '20px'
          }}>
            <Typography variant='h5' sx={{ fontWeight: 'bold', color: 'white', padding: '16px', fontSize: { xs: '20px', sm: '1.5rem' }}}>Document Automation Demo - Appellant Opening Brief</Typography>
          </Box>
          <video className="demo" controls>
            <source src={rpaDemoVideo} type="video/mp4" />
          </video>
        </Container>
        <div className='cards-list'>
          <Grid2 container spacing={2} direction="row" sx={{ alignItems: "stretch", justifyContent: "center", maxWidth: "1530px" }}>
            <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }} sx={{ display: 'flex', maxWidth: 500 }}>
              <Card raised sx={{ backgroundColor: '#001d3d' }}>
                <CardContent sx={{ color: 'white' }}>
                  <Typography variant='h5' sx={{ fontWeight: 'bold', fontSize: { xs: '20px', sm: '1.5rem' }}}>IMPROVE EFFICIENCIES</Typography>
                  <Typography>Our proven solution works around the clock to ensure your automations are done in a timely manner, reducing the amount of time it takes your staff to complete tasks.</Typography>
                </CardContent>
              </Card>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }} sx={{ display: 'flex', maxWidth: 500 }}>
              <Card raised sx={{ backgroundColor: '#001d3d' }}>
                <CardContent sx={{ color: 'white' }}>
                  <Typography variant='h5' sx={{ fontWeight: 'bold', fontSize: { xs: '20px', sm: '1.5rem' } }}>SECURITY</Typography>
                  <Typography>Our solution will operate on Federal Risk and Authorization Management Program (FedRAMP) accredited software.</Typography>
                </CardContent>
              </Card>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }} sx={{ display: 'flex', maxWidth: 500 }}>
              <Card raised sx={{ backgroundColor: '#001d3d' }}>
                <CardContent sx={{ color: 'white' }}>
                  <Typography variant='h5' sx={{ fontWeight: 'bold' }}>ADDITION BY SUBTRACTION</Typography>
                  <Typography>Enable your staff to focus on important tasks and organizational improvements by reducing the time spent on menial tasks.</Typography>
                </CardContent>
              </Card>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 6, xl: 6 }} sx={{ display: 'flex', maxWidth: 500 }}>
              <Card raised sx={{ backgroundColor: '#001d3d' }}>
                <CardContent sx={{ color: 'white' }}>
                  <Typography variant='h5' sx={{ fontWeight: 'bold', fontSize: { xs: '20px', sm: '1.5rem' } }}>SCALABILITY</Typography>
                  <Typography>Modernization is a constantly moving target, our solution will help guide your organization through the pursuit of innovation.</Typography>
                </CardContent>
              </Card>
            </Grid2>
          </Grid2>
        </div>
        <div className="contact-form">
          <h4 className="contact-form">Looking for Your Solution?</h4>
          <p>Fill out this contact form and we will reach out to you! (* = required fields)</p>
          <ContactForm />
        </div>
      </div>
    </>
  );
}


export default Conference;