import React from 'react';
import '../App.css';
import './HeroSection.css';
import backgroundVideo from '../SacramentoPan.mov';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video autoPlay loop muted paused="false" playinbackground="true" playwheninactive="true" ignoresilentswitch="ignores" playsInline={true} disablePictureInPicture={true} id ='video'>
          <source src ={backgroundVideo} type='video/mp4' />
      </video>
      <h3>Vision</h3>
      <h3>Solutions</h3>
      <h3>Execution</h3>

    </div>
  );
}

export default HeroSection;