import React from 'react'
import '../../App.css';
import ContactUsform from '../ContactUsform';

function ContactUs() {
    return (
      <>
        <ContactUsform/>
      </>
    );
  }

  export default ContactUs