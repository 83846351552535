import React from 'react'
import '../../App.css';
import ITstaff from '../ITstaff';

function ITstaffing() {
    return (
      <>
        <ITstaff/>
      </>
    );
  }

  export default ITstaffing